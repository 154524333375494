import { Alert } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faBell } from '@fortawesome/pro-duotone-svg-icons/faBell'
import { faExclamationCircle } from '@fortawesome/pro-duotone-svg-icons/faExclamationCircle'
import { faSort } from '@fortawesome/pro-duotone-svg-icons/faSort'
import { faSortDown } from '@fortawesome/pro-duotone-svg-icons/faSortDown'
import { faSortUp } from '@fortawesome/pro-duotone-svg-icons/faSortUp'
import { faTimes } from '@fortawesome/pro-duotone-svg-icons/faTimes'

import * as React from 'react'
import AdminQuickEditMenu from '../../../Components/Elements/AdminQuickEditMenu.jsx'
import ShowEntries from '../../../Components/Elements/ShowEntries.jsx'
import MultiContentDossierArchive from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierArchive.jsx'
import MultiContentDossierSubscribe from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierSubscribe.jsx'
import MultiContentDossierFavorite from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierFavorite.jsx'
import MultiContentDossierDelete from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierDelete.jsx'
import MultiContentDossierMassUpdate from '../../../Components/Pages/DefDossier/DefDossierToolbar/MultiContentDossierMassUpdate.jsx'
import ContentDossierExport from '../../../Components/Pages/DefDossier/DefDossierToolbar/ContentDossierExport.jsx'
import DisplayItemHelper from '../../../util/DisplayItemHelper'

interface ContentDossierIndexHeaderProps {
    props: any
    kedo: any
    pager: any
    sort: any
    limit: any
    clearSort: any
    applySort: any
    handleEntriesChange: any
    mode: string
    loadingContent: boolean
    onDeleteSuccess: any
    getSearchFilter: any
    dossiers: Array<any>
    subscriptions: Array<any>
    favorites: Array<any>
    listItems: Array<IDisplayField>
    actionBtns: Array<any>
    fetchDossierResults: any
    selected: Array<any>
    defDossierId: number
    showArchived: boolean
    setSelected: any
    contents: any
    searchParams: any
    dossierQuery: any
}

const ContentDossierIndexHeader: React.FC<ContentDossierIndexHeaderProps> = ({
    props,
    kedo,
    pager,
    loadingContent,
    sort,
    limit,
    clearSort,
    applySort,
    handleEntriesChange,
    mode,
    onDeleteSuccess,
    getSearchFilter,
    subscriptions,
    favorites,
    dossiers,
    listItems,
    actionBtns,
    fetchDossierResults,
    selected,
    defDossierId,
    showArchived,
    setSelected,
    contents,
    searchParams,
    dossierQuery,
}) => {
    if (!listItems) {
        return
    }

    const checkAll = (event) => {
        if (!dossiers) {
            return
        }

        if (true === event.target.checked) {
            setSelected(dossiers.map((item) => item.id))
        } else {
            setSelected([])
        }
    }

    const isSortable = (listField): boolean => {
        if (
            listField &&
            listField.settings &&
            listField.settings.dossier_data &&
            (listField.settings.dossier_data === 'created_at' ||
                listField.settings.dossier_data === 'modified_at')
        ) {
            return true
        }

        if (!listField || !listField.def_dossier_def_field) {
            return false
        }

        if (
            DisplayItemHelper.isTypeOfField(
                listField,
                DisplayItemHelper.FIELD_TEXTAREA_TYPE
            )
        ) {
            return false
        }

        return true
    }

    const isAllowedDd = (credential: string): boolean => {
        return kedo
            .env()
            .isAllowedDefDossier(credential, defDossierId, kedo.user())
    }

    const selectedDossiers = dossiers.filter((item) =>
        selected.find((selectedId) => selectedId === item.id)
    )
    const archivedDossiers =
        showArchived && selectedDossiers.find((item) => item.archived === true)
    const dearchivedDossiers =
        showArchived && selectedDossiers.find((item) => item.archived === false)

    let extraColumns = 3
    if (actionBtns.length > 0) {
        extraColumns = 4
    }

    if (loadingContent) {
        return
    }

    return (
        <thead>
            <tr>
                <td colSpan={listItems.length + extraColumns}>
                    {selected.length > 0 ? (
                        <span className={'defDossierButtons'}>
                            <MultiContentDossierSubscribe
                                kedo={kedo}
                                onClose={fetchDossierResults}
                                subscriptions={subscriptions}
                                items={selected}
                                mode={mode}
                            />
                            <MultiContentDossierFavorite
                                kedo={kedo}
                                onClose={fetchDossierResults}
                                favorites={favorites}
                                items={selected}
                            />
                            {dearchivedDossiers && isAllowedDd('archive') ? (
                                <MultiContentDossierArchive
                                    kedo={kedo}
                                    onClose={fetchDossierResults}
                                    archived={false}
                                    checked={selected}
                                    mode={mode}
                                />
                            ) : null}
                            {archivedDossiers && isAllowedDd('archive') ? (
                                <MultiContentDossierArchive
                                    kedo={kedo}
                                    onClose={fetchDossierResults}
                                    archived={true}
                                    checked={selected}
                                    mode={mode}
                                />
                            ) : null}
                            {isAllowedDd('export') ? (
                                <ContentDossierExport
                                    defDossierId={defDossierId}
                                    filter={getSearchFilter}
                                    kedo={kedo}
                                    onClose={fetchDossierResults}
                                    selected={selected}
                                    mode={
                                        mode === 'embeddedList' ||
                                        mode === 'block'
                                            ? 'all'
                                            : null
                                    }
                                />
                            ) : null}
                            {isAllowedDd('delete') ? (
                                <MultiContentDossierDelete
                                    kedo={kedo}
                                    onClose={onDeleteSuccess}
                                    items={selected}
                                    mode={mode}
                                />
                            ) : null}
                        </span>
                    ) : null}
                    <div className={'float-right'}>
                        {props.multiAdd ? <>{props.multiAdd}&nbsp;</> : null}
                        {(mode === 'embeddedList' || mode === 'block') &&
                        props.addDossier ? (
                            <>{props.addDossier}&nbsp;</>
                        ) : null}
                        {mode === 'embeddedList' && props.filterDossier ? (
                            <>{props.filterDossier}&nbsp;</>
                        ) : null}
                        {dossiers.length > 0 &&
                        selected.length <= 0 &&
                        isAllowedDd('export') ? (
                            <>
                                <ContentDossierExport
                                    defDossierId={defDossierId}
                                    kedo={kedo}
                                    filter={getSearchFilter}
                                    mode={'all'}
                                />
                                &nbsp;
                            </>
                        ) : null}
                        {dossiers?.length > 0 &&
                        (selected?.length > 0 ||
                            searchParams?.length > 0 ||
                            dossierQuery?.length > 0) &&
                        isAllowedDd('massupdate')
                            ? null
                            : // <MultiContentDossierMassUpdate
                              //     kedo={kedo}
                              //     onClose={fetchDossierResults}
                              //     mode={mode}
                              //     selectedDossierIds={
                              //         selectedDossiers?.length > 0
                              //             ? selectedDossiers?.map((ids) => ids.id)
                              //             : dossiers?.map((ids) => ids.id)
                              //     }
                              //     contents={contents}
                              // />
                              null}
                        {dossiers?.length > 0 && selected?.length <= 0 ? (
                            <>
                                <ShowEntries
                                    pager={pager}
                                    value={limit}
                                    onChange={handleEntriesChange}
                                />
                                &nbsp;
                            </>
                        ) : null}
                    </div>
                </td>
            </tr>
            {!dossiers || dossiers.length <= 0 ? (
                <Alert variant={'info'} style={{ margin: '0 17px' }}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                    &nbsp; {kedo.t('No items found')}
                </Alert>
            ) : (
                <tr>
                    <th style={{ width: '3%' }}>
                        {dossiers.length > 0 ? (
                            <input
                                checked={selected.length === dossiers.length}
                                onChange={checkAll}
                                type="checkbox"
                                style={{ marginRight: '0px' }}
                            />
                        ) : null}
                    </th>
                    {props.showEmail === true ? <th /> : null}
                    <th style={{ textAlign: 'center' }}>
                        <FontAwesomeIcon icon={faBell} />
                    </th>
                    {listItems.map((listItem) => {
                        const isSortableField = isSortable(listItem)

                        //Item is not sortable
                        if (!isSortableField) {
                            return (
                                <th data-ddiid={listItem.id} key={listItem.id}>
                                    {kedo
                                        .env()
                                        .translateItem(listItem, 'displayitem')}
                                    <AdminQuickEditMenu
                                        className={'list-admin-edit-buttons'}
                                        kedo={kedo}
                                        item={listItem}
                                    />
                                </th>
                            )
                        }

                        let sortItem = null
                        if (
                            listItem.def_dossier_def_field &&
                            listItem.def_dossier_def_field.id
                        ) {
                            sortItem = sort.find(
                                (itList) =>
                                    itList.item.def_dossier_def_field &&
                                    itList.item.def_dossier_def_field.id ===
                                        listItem.def_dossier_def_field.id
                            )
                        } else if (
                            listItem.settings &&
                            listItem.settings.dossier_data
                        ) {
                            sortItem = sort.find(
                                (itList) =>
                                    itList.item.settings &&
                                    listItem.settings.dossier_data ===
                                        itList.item.settings.dossier_data
                            )
                        }

                        //Item is sortable
                        return (
                            <th data-ddiid={listItem.id} key={listItem.id}>
                                <span
                                    onClick={(e) =>
                                        applySort(
                                            e,
                                            listItem,
                                            sortItem && sortItem.order === 'ASC'
                                                ? 'DESC'
                                                : 'ASC'
                                        )
                                    }
                                    style={{ cursor: 'pointer' }}
                                >
                                    {kedo.translateItem(
                                        listItem,
                                        'displayitem'
                                    )}{' '}
                                    &nbsp;
                                    {sortItem ? (
                                        <FontAwesomeIcon
                                            icon={
                                                sortItem.order === 'ASC'
                                                    ? faSortUp
                                                    : faSortDown
                                            }
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            className={'text-muted'}
                                            icon={faSort}
                                        />
                                    )}
                                    &nbsp;&nbsp;
                                </span>
                                {sortItem ? (
                                    <FontAwesomeIcon
                                        icon={faTimes}
                                        onClick={(e) => clearSort(e, listItem)}
                                        size={'sm'}
                                        style={{ cursor: 'pointer' }}
                                    />
                                ) : null}
                                <AdminQuickEditMenu
                                    className={'list-admin-edit-buttons'}
                                    kedo={kedo}
                                    item={listItem}
                                />
                            </th>
                        )
                    })}
                    {actionBtns.length > 0 ? (
                        <th>{kedo.t('Actions')}</th>
                    ) : null}
                    <th />
                </tr>
            )}
        </thead>
    )
}

export default ContentDossierIndexHeader
