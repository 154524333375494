import * as React from 'react'
import { RefObject, useEffect, useRef, useState } from 'react'
import { Row, Col, Button, Alert, Modal } from 'react-bootstrap'
import { useParams, useHistory } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faSave } from '@fortawesome/pro-duotone-svg-icons/faSave'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle'
import { faTimesCircle } from '@fortawesome/pro-duotone-svg-icons/faTimesCircle'
import { faChevronLeft } from '@fortawesome/pro-duotone-svg-icons/faChevronLeft'

import { toast } from 'react-toastify'
import { ToastOptions } from 'react-toastify/dist/types'
import BagHelper from '../../../util/BagHelper'
import DefaultGrid from '../../../Components/Grid/DefaultGrid.jsx'
import ShowMultiAdd from '../../../Components/Pages/ContentDossier/NewDossier/ShowMultiAdd'
import LoadingPage from '../../../Components/Elements/Loading/LoadingPage.jsx'
import LoadingDefault from '../../../Components/Elements/Loading/LoadingDefault.jsx'
import EntityHelper from '../../../util/EntityHelper'
import {
    IDisplayPositionList,
    useEntityService,
} from '../../../context/EntityServiceContext'
import DisplayItemHelper from '../../../util/DisplayItemHelper'
import DetailContentDossier from '../Detail/ContentDossier'
import Kedo from '../../../Kedo.js'
import Sidebar from '../../../Components/Elements/Sidebar/Sidebar'

type Props = {
    kedo: Kedo
    props: any
}

const CreateContentDossier = ({ kedo, props }: Props): JSX.Element => {
    const toastOptions = {
        position: 'bottom-center',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    } as ToastOptions
    const params = useParams()
    const entityService = useEntityService()
    const [defDossier, setDefDossier] = useState(null)
    const [loading, setLoading] = useState(false)
    const [showSaveSuccess, setShowSaveSuccess] = useState(false)
    const [denied_error, setDenied_error] = useState(false)
    const [showExisting, setShowExisting] = useState([])
    const [showMultiAddModal, setShowMultiAddModal] = useState(false)
    const [existingConstraintType, setExistingConstraintType] = useState('')
    const [ignoreWarning, setIgnoreWarning] = useState(false)
    const [embedded, setEmbedded] = useState(false)
    const [saved, setSaved] = useState(false)
    const [changed, setChanged] = useState({})
    const [submitting, setSubmitting] = useState(false)
    const [redirect, setRedirect] = useState(false)
    const [edit, setEdit] = useState(true)
    const [content, setContent] = useState(props.content ? props.content : {})
    const [mainPos, setMainPos] = useState({})
    const [errors, setErrors] = useState({})
    const [items, setItems] = useState([])
    const [showChangeModal, setShowChangeModal] = useState(null)
    const [values, setValues] = useState([])
    const [showExistingUserModal, setShowExistingUserModal] = useState(false)
    const [existingUser, setExistingUser] = useState(null)
    const [id, setId] = useState(
        props.defDossierId ? props.defDossierId : params.id
    )
    const refs = useRef([])
    let curRef = useRef()
    let dossierRef = useRef()

    const fetchDisplayPositions = async (defDossierId) => {
        const dpUrl =
            kedo.api().getDisplayPositionEndpoint() +
            '?defDossier=' +
            defDossierId
        return await kedo.api().getCached(dpUrl)
    }

    const fetchDisplayItems = async () => {
        const ddiParams = {
            params: {
                view: ['edit', 'show/edit'],
                defDossier: id,
                displayPosition: ['main_content'],
                sort: ['rank', 'col'],
                sortOrder: ['ASC', 'ASC'],
            },
        }

        setLoading(true)

        const ddPositions = (await entityService.getDisplayPositions(
            id
        )) as IDisplayPositionList

        const entity = (await entityService.getEntity(id)) as Entity

        const mainPos = ddPositions.results.find(
            (dpItem) => dpItem.type === 'main_content'
        )

        setDefDossier(entity)
        setMainPos(mainPos)

        const roleIds = kedo
            .env()
            .getCurrentEnvironmentRoles()
            .map((item) => item.id)

        setItems(
            DisplayItemHelper.filterViews(mainPos.display_items, [
                DisplayItemHelper.EDIT_VIEW,
                DisplayItemHelper.SHOW_EDIT_VIEW,
            ]).filter((item) => {
                if (
                    !item.permissions_type ||
                    kedo.isAdminOrEnvironmentAdmin()
                ) {
                    return true
                }

                if (item.permissions_type === 'INCLUDE_SELECTED_ROLES') {
                    if (
                        item.roles.filter((roleItem) =>
                            roleIds.includes(roleItem.role.id)
                        ).length > 0
                    ) {
                        return true
                    } else {
                        return false
                    }
                } else if (item.permissions_type === 'EXCLUDE_SELECTED_ROLES') {
                    if (
                        item.roles.filter((roleItem) =>
                            roleIds.includes(roleItem.role.id)
                        ).length <= 0
                    ) {
                        return true
                    } else {
                        return false
                    }
                }

                return true
            })
        )
        setLoading(false)
    }

    const getCurrentRef = (): RefObject<any> => {
        if (!curRef) {
            curRef = React.createRef()
        }

        return curRef
    }

    const getDossierRef = (): RefObject<any> => {
        if (!dossierRef) {
            dossierRef = React.createRef()
        }

        return dossierRef
    }

    const findAddress = (displayItem, changedDdiId) => {
        BagHelper.autocomplete(kedo, defDossier, getCurrentRef())
    }

    const onChangeValue = (itemId, value, item) => {
        //BAG: Lookup addresses
        if (
            defDossier &&
            defDossier.settings &&
            defDossier.settings.address_lookup_settings &&
            defDossier.settings.address_lookup_settings.address_lookup === true
        ) {
            if (
                parseInt(
                    defDossier.settings.address_lookup_settings.zipcode_ddi
                ) === itemId
            ) {
                findAddress(item, itemId)
            } else if (
                parseInt(
                    defDossier.settings.address_lookup_settings.housenr_ddi
                ) === itemId
            ) {
                findAddress(item, itemId)
            }
        }

        setValues(value)

        //TODO KeDo: Check conditions here.
        //TODO KeDo: Check other modules here and maybe change data based on
    }

    const renderUnsavedChangesModal = () => {
        return (
            <Modal centered show={true} onHide={() => setShowChangeModal(null)}>
                <Modal.Header closeButton>
                    <Modal.Title>{props.kedo.t('Confirmation')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.kedo.t('You made unsaved changes')}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        onClick={() =>
                            showChangeModal === true
                                ? props.embedded
                                    ? props.onClose()
                                    : props.history.goBack()
                                : props.history.push(showChangeModal)
                        }
                    >
                        {submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : null}
                        {props.kedo.t('Close')}
                    </Button>{' '}
                    &nbsp;
                    <Button
                        variant="secondary"
                        onClick={() => setShowChangeModal(null)}
                    >
                        {props.kedo.t('Cancel')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const submitDossier = (addContinue): any => {
        setSubmitting(true)

        const values = getCurrentRef().current.getValues()
        if (props.embedded && props.embedded !== true) {
            values['embedded'] = props.embedded
            values['linkId'] = props.linkId
        }

        if (ignoreWarning === true) {
            values['ignore_existing'] = true
        }

        values.defDossier = id

        const submitErrors = EntityHelper.checkCanSubmitDisplayPosition(
            kedo,
            defDossier,
            mainPos,
            getCurrentRef(),
            values,
            ignoreWarning
        )

        if (Object.keys(submitErrors).length > 0) {
            setErrors(submitErrors)
            setSubmitting(false)
            const errorId = checkErrors(submitErrors)
            getCurrentRef().current.doFocus(errorId)
            return
        }

        kedo.api()
            .post(kedo.api().getContentDossierEndpoint(), values)
            .then((response) => {
                if (addContinue) {
                    setEdit(false)
                    setSubmitting(false)
                    setErrors([])
                    setContent({})
                    setChanged({})
                    setSaved(true)
                    setTimeout(() => setSaved(false), 5000)
                    setTimeout(() => fetchDisplayItems(), 500)
                } else {
                    setRedirect(
                        props.location === 'Block' ? false : response.data.id
                    )
                    setEdit(false)
                    setSubmitting(false)
                    setErrors([])
                }

                toast.success(
                    kedo.t('dossier_errors.success_create', {
                        defdossier: kedo
                            .env()
                            .translateItem(defDossier, 'defdossier'),
                    }),
                    toastOptions
                )

                if (
                    props.location === 'Block' ||
                    (props.location === 'EmbeddedLinkField' && !addContinue)
                ) {
                    props.onSuccess(response.data.id)
                }
            })
            .catch((error) => {
                if (error.response && error.response.status === 409) {
                    setErrors(error.response.data.errors)
                    setSubmitting(false)
                    if (
                        error.response.data.existing &&
                        error.response.data.existing.length > 0
                    ) {
                        setShowExisting(error.response.data.existing)
                        setExistingConstraintType(
                            error.response.data.constraint_type
                        )
                    }
                } else if (error.response && error.response.status === 403) {
                    setDenied_error(kedo.t('not_allowed_to_create_dossier'))
                    setSubmitting(false)

                    toast.error(
                        kedo.t('dossier_errors.permission_create'),
                        toastOptions
                    )
                    window.scrollTo(0, 0)
                } else {
                    toast.error(
                        kedo.t('dossier_errors.error_create'),
                        toastOptions
                    )
                    setErrors(error.response.data.errors)
                    setSubmitting(false)

                    const errorId = checkErrors(false)
                    getCurrentRef().current.doFocus(errorId)
                }
            })
    }

    const getAllValues = () => {
        const values = getCurrentRef().current.getValues()
        if (props.embedded && props.embedded !== true) {
            values['embedded'] = props.embedded
            values['linkId'] = props.linkId
        }

        return values
    }

    const preSubmitDossier = () => {
        if (props.multiAdd) {
            setShowMultiAddModal(getAllValues())

            return
        }

        submitDossier(false)
    }

    const renderButtons = () => {
        const addContinue =
            defDossier &&
            defDossier.settings &&
            defDossier.settings.add_continue === true
                ? true
                : false
        return (
            <div className={'float-right'}>
                <Button
                    disabled={submitting ? true : false}
                    type="submit"
                    onClick={() => preSubmitDossier()}
                    title="Create"
                    variant="primary"
                >
                    {submitting ? (
                        <LoadingDefault size={'sm'} as={'span'} />
                    ) : (
                        <FontAwesomeIcon icon={faSave} />
                    )}
                    &nbsp; {kedo.t('Add')}
                </Button>{' '}
                &nbsp;
                {addContinue ? (
                    <Button
                        disabled={submitting ? true : false}
                        type="submit"
                        onClick={() => submitDossier(true)}
                        title="Create"
                        variant="primary"
                    >
                        {submitting ? (
                            <LoadingDefault size={'sm'} as={'span'} />
                        ) : (
                            <FontAwesomeIcon icon={faSave} />
                        )}
                        &nbsp; {kedo.t('Add and continue')}
                    </Button>
                ) : null}
                {props.location === 'Block' ||
                props.location === 'EmbeddedLinkField' ? (
                    <Button
                        variant="secondary"
                        title={kedo.t('Cancel')}
                        onClick={() => {
                            values.length !== 0
                                ? setShowChangeModal(true)
                                : props.onClose()
                        }}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        &nbsp; {kedo.t('Cancel')}
                    </Button>
                ) : (
                    <Button
                        variant="secondary"
                        title={kedo.t('Cancel')}
                        onClick={() => {
                            values.length !== 0
                                ? setShowChangeModal(true)
                                : props.history.goBack()
                        }}
                    >
                        <FontAwesomeIcon icon={faTimesCircle} />
                        &nbsp; {kedo.t('Cancel')}
                    </Button>
                )}
            </div>
        )
    }

    const renderSuccessAlert = () => {
        return (
            <Alert key={'success'} variant={'success'}>
                <FontAwesomeIcon icon={faCheckCircle} />
                &nbsp; {kedo.t('Dossier has been saved successfully')}
            </Alert>
        )
    }

    const checkErrors = (errors) => {
        const values = []
        const newItems = []
        items
            .filter((item) => item.view === 'edit' || item.view === 'show/edit')
            .map((item) => newItems.push(item))

        newItems.map((filterItem) => {
            values.push({
                id: filterItem.id,
                type: filterItem.def_dossier_def_field
                    ? filterItem.def_dossier_def_field.def_field.type
                    : filterItem.def_dossier_link,
                error: errors ? errors[filterItem.id] : errors[filterItem.id],
            })
        })

        const focusItem = values.find((item) => item.error)

        if (focusItem) {
            return focusItem.id
        }
    }

    const handleClick = (e) => {
        if (!getDossierRef()?.current.contains(e.target)) {
            if (
                e.target.tagName.toLowerCase() === 'a' ||
                e.target.parentNode.tagName.toLowerCase() === 'a'
            ) {
                e.preventDefault()
                const href = e.target.closest('a').getAttribute('href')
                values.length > 0 && href !== '#'
                    ? setShowChangeModal(href)
                    : null
            }
            if (e.target.tagName.toLowerCase() === 'span') {
                e.preventDefault()
                values.length > 0 ? setShowChangeModal(true) : null
            }
        }
    }

    useEffect(() => {
        fetchDisplayItems()
    }, [])

    useEffect(() => {
        document.addEventListener('mousedown', handleClick)
        return () => {
            document.removeEventListener('mousedown', handleClick)
        }
    }, [values])

    const renderExistingUser = () => {
        return (
            <Modal
                size="lg"
                centered
                show={true}
                onHide={() => {
                    setShowExistingUserModal(false)
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{existingUser.summary}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DetailContentDossier
                        props={{
                            existingUser: true,
                            modalized: true,
                            dossierId: existingUser.id,
                            disableBreadcrumb: true,
                        }}
                        kedo={kedo}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {existingConstraintType === 'warning' ? (
                        <Button
                            onClick={() => {
                                setIgnoreWarning(true)
                                setTimeout(
                                    () =>
                                        props.onSuccess
                                            ? props.onSuccess(existingUser.id)
                                            : props.history.goBack(),
                                    50
                                )
                            }}
                        >
                            {kedo.t('Use this user')}
                        </Button>
                    ) : null}
                    <Button
                        onClick={() => setShowExistingUserModal(false)}
                        variant={'secondary'}
                    >
                        {kedo.t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }

    const renderExisting = () => {
        return (
            <>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={true}
                    onHide={() => setShowExisting([])}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            {kedo.t('Already existing items')}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{kedo.t('existing_items_description')}</p>
                        <ul className={'list-group'}>
                            {showExisting.map((existingItem) => (
                                <li
                                    key={existingItem.id}
                                    className={
                                        'list-group-item list-group-item-action'
                                    }
                                    onClick={() => {
                                        setShowExistingUserModal(true)
                                        setExistingUser(existingItem)
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {existingItem.summary
                                        ? existingItem.summary
                                        : existingItem.id}
                                </li>
                            ))}
                        </ul>
                    </Modal.Body>
                    <Modal.Footer>
                        {existingConstraintType === 'warning' ? (
                            <Button
                                onClick={() => {
                                    setIgnoreWarning(true)
                                    setTimeout(() => submitDossier(false), 50)
                                }}
                            >
                                {kedo.t('Ignore warning and save')}
                            </Button>
                        ) : null}
                        <Button
                            onClick={() => setShowExisting([])}
                            variant={'secondary'}
                        >
                            {kedo.t('Close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
                {showExistingUserModal !== false ? renderExistingUser() : null}
            </>
        )
    }

    const renderMultiAdd = () => {
        //TODO: Add check
        return (
            <ShowMultiAdd
                defDossier={defDossier}
                kedo={kedo}
                displayItems={items}
                handleGetValues={() => getCurrentRef().current.getValues()}
                handleSuccess={props.onSuccess}
                handleClose={() => setShowMultiAddModal(false)}
            />
        )
    }

    if (redirect !== false) {
        if (props.embedded) {
            // this.props.onSuccess(this.state.redirect);
            return null
        }
        return <Redirect to={`/contentdossier/${redirect}`} />
    }

    if (
        !defDossier ||
        loading ||
        (props.match && id !== props.match.params.id)
    ) {
        return <LoadingPage />
    }

    return (
        <div ref={dossierRef}>
            <Row>
                {props.leftSideBar && window.innerWidth > 576 ? (
                    <Col style={{ borderRight: '1px solid #dee2e6' }} xs={4}>
                        <Sidebar
                            kedo={kedo}
                            defDossier={defDossier}
                            embeddedDefDossierId={props.embeddedDefDossierId}
                            dossierId={props.embedded}
                            leftSideBar={props.leftSideBar}
                        />
                    </Col>
                ) : null}
                <Col xs={props.leftSideBar && window.innerWidth > 576 ? 8 : 12}>
                    {showMultiAddModal ? renderMultiAdd() : null}
                    {showExisting.length > 0 ? renderExisting() : null}
                    {denied_error !== false ? (
                        <Alert variant={'warning'}>{denied_error}</Alert>
                    ) : null}
                    {showChangeModal ? renderUnsavedChangesModal() : null}
                    {saved === true ? (
                        <Alert variant={'success'}>
                            {kedo.t('Dossier has been saved successfully')}
                        </Alert>
                    ) : null}
                    <div
                        className={
                            props.location === 'EmbeddedLinkField'
                                ? ''
                                : 'mainContainer'
                        }
                    >
                        {showSaveSuccess === true ? renderSuccessAlert() : null}
                        {props.location === 'EmbeddedLinkField' ? null : (
                            <Row>
                                <Col xs={8}>
                                    <h1>
                                        {props.multiAdd ? (
                                            <small>Multi add </small>
                                        ) : null}
                                        {kedo.t('New')}{' '}
                                        {kedo
                                            .env()
                                            .translateItem(
                                                defDossier,
                                                'defdossier'
                                            )}
                                    </h1>
                                </Col>
                                {items.length ? (
                                    <Col xs={4}>{renderButtons()}</Col>
                                ) : null}
                            </Row>
                        )}
                        <div>
                            {props.multiAdd ? (
                                <Alert variant={'info'}>
                                    {kedo.t('multi_add_dossier_header')}
                                </Alert>
                            ) : null}
                            <DefaultGrid
                                changeValue={onChangeValue}
                                conditions={
                                    defDossier &&
                                    defDossier.settings &&
                                    defDossier.settings.conditions
                                        ? defDossier.settings.conditions
                                        : []
                                }
                                content={content}
                                embedded={props.embedded}
                                linkId={props.linkId}
                                errors={errors}
                                hiddenfields={
                                    defDossier &&
                                    defDossier.settings &&
                                    defDossier.settings.hiddenfields
                                        ? defDossier.settings.hiddenfields
                                        : []
                                }
                                items={items}
                                kedo={kedo}
                                mode={'edit'}
                                ref={getCurrentRef()}
                            />
                            <Row>
                                <Col xs={12}>{renderButtons()}</Col>
                            </Row>
                        </div>
                    </div>
                </Col>
                {props.defDossierId ? null : (
                    <Col xs={12}>
                        <Button
                            className={'goBackButton'}
                            variant={'secondary'}
                            onClick={() => props.history.goBack()}
                        >
                            <FontAwesomeIcon icon={faChevronLeft} />
                            &nbsp; {kedo.t('Back to overview')}
                        </Button>
                    </Col>
                )}
            </Row>
        </div>
    )
}

export default CreateContentDossier
