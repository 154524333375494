import React from 'react'
import { ResponsivePie } from '@nivo/pie'
import { ResponsiveLine } from '@nivo/line'
import { ResponsiveBar } from '@nivo/bar'
import { Table } from 'react-bootstrap'
import LoadingDefault from '../../Elements/Loading/LoadingDefault'
import { Link } from 'react-router-dom'
import BlockToolbar from './BlockToolbar'

export default class BlockCountLink extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            block: this.props.block,
            data: null,
            transformedData: null,
            transformedKeys: null,
            dateFormat:
                this.props.block &&
                this.props.block.settings &&
                this.props.block.settings.date_format
                    ? this.props.block.settings.date_format
                    : 'year_month',
            loading: false,
            pager: null,
            originField: null,
            blockSettings: this.props.block ? this.props.block.settings : null,
            pageError: false,
        }
        this.parRef = React.createRef()
        this.setFilterAndFetchData = this.setFilterAndFetchData.bind(this)
    }

    getParentRef() {
        return this.parRef
    }

    setFilterAndFetchData(key, val) {
        this.setState({ [key]: val }, this.fetchData)
    }

    fetchData(disableLoader) {
        if (!this.props) {
            return
        }
        let environment = this.props.kedo.env().getEnvironment()
        if (!environment) {
            return
        }

        if (
            !this.props.block.id ||
            !this.props.block.settings.count_link_field
        ) {
            return
        }

        let api = this.props.kedo.api()

        let params = { params: {} }

        if (this.props.dossier && this.props.dossier.id) {
            params.dossier = this.props.dossier.id
            params.params.dossier = this.props.dossier.id
            params.params.linkId = this.props.link
            params.linkId = this.props.link
        }

        if (this.state.dateFormat) {
            params.params.date_format = this.state.dateFormat
        }

        if (disableLoader !== false) {
            this.setState({ loading: true })
        }

        api.get(
            api.getMyPageBlockEndpoint(this.props.block.id) + '/data',
            params
        ).then((response) => {
            let transFormedData = this.transformData(
                this.props.block,
                response.data
            )
            let transFormedKeys = this.getTransformedKeys(
                this.props.block,
                transFormedData
            )
            this.setState({
                data: response.data,
                transformedData: transFormedData,
                transformedKeys: transFormedKeys,
                loading: false,
            })
            if (this.props.block.settings?.origin_display_field) {
                api.getCached(
                    api.getDefDossierDefFieldEndpoint(
                        this.props.block.settings?.origin_display_field
                    )
                ).then((originResponse) =>
                    this.setState({ originField: originResponse.data })
                )
            }
        })
    }

    getTransformedKeys(block, transformedData) {
        //Transform date orientated data.
        let chartFormat = block.settings?.chart_format
        if (block.settings?.use_creation_date || block.settings?.date_field) {
            if (chartFormat === 'bar') {
                return this.getUniqueFromData(transformedData, 'summary')
            }
        }
    }

    transformData(block, data) {
        //Transform date orientated data.
        let chartFormat = block.settings?.chart_format
        if (block.settings?.use_creation_date || block.settings?.date_field) {
            if (chartFormat === 'bar') {
                return this.transFormDateBarData(data)
            } else if (chartFormat === 'line') {
                return this.transformLineData(data)
            }
        } else if (chartFormat === 'bar') {
            return this.transFormBarData(data)
        }

        return []
    }

    transformLineData(data) {
        let lineData = []
        let line = []

        data.map((item) => {
            let label =
                item['summary'] && item['summary'].length > 0
                    ? item['summary']
                    : this.props.kedo.t('Unknown')
            line.push({
                x: label,
                y: parseInt(item['1']),
                kedoId: item['2'],
            })
        })

        if (line.length > 0) {
            lineData.push({
                id: this.props.kedo.t('Items'),
                color: 'hsl(335, 70%, 50%)',
                data: line,
            })
        }

        return lineData
    }

    transFormDateData(data) {
        let lineData = []

        if (!this.state.listField) {
            return []
        }

        let defFieldLists = this.state.listField.def_field.def_field_lists
        defFieldLists.map((listItem) => {
            let listTrans = this.props.kedo
                .env()
                .translateItem(listItem, 'def_field_list', true)
            let listData = []
            data.map((dataItem) => {
                if (
                    dataItem['2'] !== null &&
                    parseInt(dataItem['2']) === listItem.id
                ) {
                    listData.push({
                        x: dataItem['yearAnddate'],
                        y: parseInt(dataItem['1']),
                    })
                }
            })

            lineData.push({
                id:
                    listTrans && listTrans.length > 0
                        ? listTrans
                        : this.props.kedo.t('No value'),
                data: listData,
            })
        })

        let unknownListData = []
        data.map((dataItem) => {
            let itemFound = false
            defFieldLists.map((listItem) => {
                if (parseInt(dataItem['2']) === listItem.id) {
                    itemFound = true
                }
            })

            if (!itemFound) {
                unknownListData.push({
                    x: dataItem['yearAnddate'],
                    y: parseInt(dataItem['1']),
                })
            }
        })

        lineData.push({
            id: this.props.kedo.t('Unknown'),
            data: unknownListData,
        })

        return lineData
    }

    transFormBarData(data) {
        let barData = []
        let overallTotal = {
            id: this.props.kedo.t('Total'),
            label: this.props.kedo.t('Total'),
            value: 0,
        }
        data.map((item) => {
            let label =
                item['summary'] && item['summary'].length > 0
                    ? item['summary']
                    : this.props.kedo.t('Unknown')

            barData.push({
                id: label,
                label: label,
                kedoId: item['2'],
                value: parseInt(item['1']),
                //"color": "hsl(20,100%,43%)"
            })
            overallTotal['value'] = overallTotal['value'] + parseInt(item['1'])
        })
        barData.push(overallTotal)
        return barData
    }

    transFormData(data) {
        let pieData = []

        data.map((item) => {
            let label =
                item['summary'] && item['summary'].length > 0
                    ? item['summary']
                    : this.props.kedo.t('Unknown')
            pieData.push({
                id: label,
                label: label,
                kedoId: item['2'],
                value: parseInt(item['1']),
                //"color": "hsl(20,100%,43%)"
            })
        })

        return pieData
    }

    componentDidMount() {
        this.fetchData()
    }

    transFormDateBarData(data) {
        let kVal = '1'
        let xKey = 'yearAnddate'
        let yKey = 'summary'
        let xItems = this.getUniqueFromData(data, xKey)
        let results = []
        let yItems = this.getUniqueFromData(data, yKey)

        for (let i = 0; i < xItems.length; i++) {
            let newResult = {}
            newResult.period = xItems[i]

            for (let b = 0; b < yItems.length; b++) {
                let keyName =
                    !yItems[b] ||
                    yItems[b] === null ||
                    typeof yItems[b] === 'undefined' ||
                    yItems[b] === undefined
                        ? 'Unknown'
                        : yItems[b]
                let keyColor = null //TODO: Maybe name to color
                let periodTotal = 0
                for (let d = 0; d < data.length; d++) {
                    if (
                        data[d][xKey] !== null &&
                        data[d][xKey].toString() === xItems[i] &&
                        data[d][yKey].toString() === yItems[b]
                    ) {
                        periodTotal = parseInt(data[d][kVal])
                        break
                    }
                }
                newResult[keyName] = periodTotal
                //newResult[keyColor] = null;
            }

            results.push(newResult)
        }

        return results
    }

    getTranslationByXItem(unXitem, originField) {
        if (originField.def_field && originField.def_field.type === 'list') {
            let listItem = originField.def_field.def_field_lists.find(
                (listItem) => listItem.id === parseInt(unXitem)
            )
            if (listItem) {
                return this.props.kedo
                    .env()
                    .translateItem(listItem, 'def_field_list')
            }
        }

        return unXitem
    }

    getKeys(blockSettings, data) {
        let items = []
        if (data && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                if (
                    data[i]['summary'] !== null &&
                    !items.includes(data[i]['summary'].toString())
                ) {
                    items.push(data[i]['summary'].toString())
                }
            }
        }

        return items
    }

    getUniqueFromData(data, keyName) {
        var uniques = []
        for (let i = 0; i < data.length; i++) {
            if (typeof data[i][keyName] === 'undefined') {
                uniques.push(this.props.kedo.t('Unknown'))
            } else if (
                data[i][keyName] !== null &&
                data[i][keyName].toString &&
                !uniques.includes(data[i][keyName].toString())
            ) {
                uniques.push(data[i][keyName].toString())
            }
        }

        return uniques
    }

    renderDateOrientatedCharts(chartFormat, data, useColors) {
        const kedo = this.props.kedo
        if (this.state.blockSettings.chart_format === 'table') {
            let yKey = 'summary'
            let xKey = 'yearAnddate'

            if (this.state.blockSettings?.reverse_axis === 'yes') {
                xKey = 'summary'
                yKey = 'yearAnddate'
            }

            let vKey = '1'
            let dosKey = '2'
            let yItems = this.getUniqueFromData(data, yKey)
            let xItems = this.getUniqueFromData(data, xKey)
            let tableVer = this.state.blockSettings?.table_layout
                ? this.state.blockSettings.table_layout
                : ''

            return (
                <div className={`table100 ${tableVer}`}>
                    <Table responsive>
                        <thead>
                            <tr className={`row100 head`}>
                                <th className={`column100 column1`} />
                                {xItems.map((xItem, xIndex) => (
                                    <th
                                        key={xIndex}
                                        className={`column100 column${
                                            xIndex + 2
                                        }`}
                                    >
                                        {xItem}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {yItems.map((yItem, yIndex) => {
                                let linkItem = data.find(
                                    (dataItem) => dataItem[yKey] === yItem
                                )

                                return (
                                    <tr key={yIndex} className={'row100'}>
                                        <th className={'column100 column1'}>
                                            {linkItem && linkItem[dosKey] ? (
                                                <Link
                                                    to={`/contentdossier/${linkItem[dosKey]}`}
                                                >
                                                    {yItem}
                                                </Link>
                                            ) : (
                                                yItem
                                            )}
                                        </th>
                                        {xItems.map((xItem, xIndex) => {
                                            let foundItem = data.find(
                                                (dataItem) =>
                                                    dataItem[xKey] === xItem &&
                                                    dataItem[
                                                        yKey
                                                    ].toString() === yItem
                                            )
                                            if (
                                                foundItem &&
                                                foundItem[dosKey]
                                            ) {
                                                return (
                                                    <td
                                                        key={xIndex}
                                                        className={`column100 column${
                                                            xIndex + 2
                                                        }`}
                                                    >
                                                        <Link
                                                            to={`/contentdossier/${foundItem[dosKey]}`}
                                                        >
                                                            {foundItem
                                                                ? foundItem[
                                                                      vKey
                                                                  ]
                                                                : 0}
                                                        </Link>
                                                    </td>
                                                )
                                            }
                                            return (
                                                <td
                                                    key={xIndex}
                                                    className={`column100`}
                                                >
                                                    {foundItem
                                                        ? foundItem[vKey]
                                                        : 0}
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>
                    </Table>
                </div>
            )
        } else if (chartFormat === 'bar') {
            //let barData = this.transFormDateBarData(data);
            let keys = this.getUniqueFromData(data, 'summary')
            let groupMode =
                this.state.blockSettings.group_format === 'stacked'
                    ? 'stacked'
                    : 'grouped'
            let chartLayout =
                this.state.blockSettings.chart_layout === 'horizontal'
                    ? 'horizontal'
                    : 'vertical'

            return (
                <ResponsiveBar
                    indexBy="period"
                    margin={{ top: 0, right: 130, bottom: 70, left: 60 }}
                    padding={0.3}
                    keys={keys}
                    yFormat=" >-.2f"
                    motionConfig="gentle"
                    layout={chartLayout}
                    groupMode={groupMode}
                    useMesh={false}
                    data={this.state.transformedData}
                    enableLabel={false}
                    axisTop={null}
                    axisRight={null}
                    theme={{
                        axis: {
                            legend: {
                                text: {
                                    fontWeight: 700,
                                },
                            },
                        },
                    }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        //fontWeight: 700,
                        tickRotation: 0,
                        legend: kedo.t('Date'),
                        legendPosition: 'middle',
                        legendOffset: 32,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: kedo.t('Totals'),
                        legendPosition: 'middle',
                        legendOffset: -50,
                    }}
                    legends={[
                        {
                            dataFrom: 'keys',
                            anchor: 'right',
                            direction: 'column',
                            justify: false,
                            translateX: 120,
                            translateY: 50,
                            itemsSpacing: 2,
                            itemWidth: 80,
                            itemHeight: 20,
                            itemDirection: 'left-to-right',
                            itemOpacity: 0.85,
                            symbolSize: 20,
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            )
        }

        //let transformedData = this.transFormDateData(data);
        return (
            <ResponsiveLine
                colors={useColors}
                data={this.state.transformedData}
                margin={{ top: 0, right: 110, bottom: 100, left: 60 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: 'auto',
                    max: 'auto',
                    stacked: true,
                    reverse: false,
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: kedo.t('Date'),
                    legendOffset: 36,
                    legendPosition: 'middle',
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: kedo.t('Totals'),
                    legendOffset: -40,
                    legendPosition: 'middle',
                }}
                pointSize={4}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={false}
                legends={[
                    {
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 100,
                        translateY: 0,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
            />
        )
    }

    calculateTotal(data, xKey, unXItem, yKey, unYItem, vKey) {
        const filteredData = data.filter(
            (dataItem) =>
                dataItem[xKey] === unXItem &&
                dataItem[yKey] !== null &&
                unYItem !== null &&
                dataItem[yKey].toString() === unYItem.toString()
        )

        const total = filteredData.reduce(
            (acc, dataItem) => acc + parseInt(dataItem[vKey]),
            0
        )

        return total
    }

    calculateOther(data, yKey, unYItem) {
        const totalOther = data.filter((dataItem) => {
            const yValue = dataItem[yKey]
            return (
                yValue !== undefined &&
                yValue !== null &&
                yValue.toString() === unYItem.toString()
            )
        })

        let valueOther = null

        totalOther.forEach((obj) => {
            if (obj.summary === null) {
                valueOther = obj[1]
            }
        })

        return valueOther
    }

    calculateTotalY(data, yKey, unYItem, vKey) {
        const totalForYItem = data
            .filter((dataItem) => {
                const yValue = dataItem[yKey]
                return (
                    yValue !== undefined &&
                    yValue !== null &&
                    yValue.toString() === unYItem.toString()
                )
            })
            .map((dataItem) => parseInt(dataItem[vKey]))
            .reduce((prev, curr) => prev + curr, 0)

        return totalForYItem
    }

    renderNoneDateOrientatedCharts(chartFormat, data, useColors) {
        const kedo = this.props.kedo
        if (chartFormat === 'table') {
            if (this.state.blockSettings?.origin_display_field) {
                if (!this.state.originField) {
                    return <LoadingDefault />
                }
                let vKey = '1'
                let dosKey = '2'
                let xKey = 'summary'
                let yKey = 'summary2'
                let uniqueX = this.getUniqueFromData(data, xKey)
                let uniqueY = this.getUniqueFromData(data, yKey)
                let tableVer = this.state.blockSettings?.table_layout
                    ? this.state.blockSettings.table_layout
                    : ''
                let accumulatedTotal = 0
                return (
                    <div className={`table100 ${tableVer}`}>
                        <Table responsive>
                            <thead>
                                <tr className={`row100 head`}>
                                    <th />
                                    {uniqueX.map((unXItem, unXIndex) => {
                                        let linkItem = data.find(
                                            (dataItem) =>
                                                dataItem[xKey] === unXItem
                                        )

                                        if (linkItem && linkItem[dosKey]) {
                                            return (
                                                <th key={unXIndex}>
                                                    <Link
                                                        to={`/contentdossier/${linkItem[dosKey]}`}
                                                    >
                                                        {this.getTranslationByXItem(
                                                            unXItem,
                                                            this.state
                                                                .originField
                                                        )}
                                                    </Link>
                                                </th>
                                            )
                                        }

                                        return (
                                            <th key={unXIndex}>
                                                {this.getTranslationByXItem(
                                                    unXItem,
                                                    this.state.originField
                                                )}
                                            </th>
                                        )
                                    })}{' '}
                                    <th>{kedo.t('Other')}</th>
                                    <th>{kedo.t('Total')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {uniqueY.map((unYItem, unYIndex) => {
                                    return (
                                        <tr key={unYIndex} className={`row100`}>
                                            <td className={`column100 column1`}>
                                                {this.getTranslationByXItem(
                                                    unYItem,
                                                    this.state.originField
                                                )}
                                            </td>
                                            {uniqueX.map(
                                                (unXItem, unXIndex) => (
                                                    <td key={unXIndex}>
                                                        {this.calculateTotal(
                                                            data,
                                                            xKey,
                                                            unXItem,
                                                            yKey,
                                                            unYItem,
                                                            vKey
                                                        )}
                                                    </td>
                                                )
                                            )}
                                            <td>
                                                {this.calculateOther(
                                                    data,
                                                    yKey,
                                                    unYItem,
                                                    vKey
                                                )}
                                            </td>
                                            <td className={`column100 column1`}>
                                                {this.calculateTotalY(
                                                    data,
                                                    yKey,
                                                    unYItem,
                                                    vKey
                                                )}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th>{kedo.t('Total')}</th>
                                    {uniqueX.map((unXItem, unYIndex) => (
                                        <td key={unYIndex}>
                                            {data
                                                .filter(
                                                    (dataItem) =>
                                                        dataItem[xKey] ===
                                                            unXItem &&
                                                        dataItem[yKey] !== null
                                                )
                                                .map((dataItem) =>
                                                    parseInt(dataItem[vKey])
                                                )
                                                .reduce(
                                                    (prev, curr) => prev + curr,
                                                    0
                                                )}
                                        </td>
                                    ))}
                                    {uniqueY.map((unYItem) => {
                                        const currentTotal =
                                            this.calculateOther(
                                                data,
                                                yKey,
                                                unYItem,
                                                vKey
                                            )
                                        accumulatedTotal += currentTotal
                                        return null
                                    })}
                                    <td> {accumulatedTotal}</td>
                                    <td>
                                        {data
                                            .filter(
                                                (dataItem) =>
                                                    dataItem[yKey] !== null
                                            )
                                            .map((dataItem) =>
                                                parseInt(dataItem[vKey])
                                            )
                                            .reduce(
                                                (prev, curr) => prev + curr,
                                                0
                                            )}
                                    </td>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                )
            } else {
                return <small>Not supported settings.</small>
            }
        } else if (chartFormat === 'bar') {
            //let barData = this.transFormBarData(data);
            let groupMode =
                this.state.blockSettings.group_format === 'stacked'
                    ? 'stacked'
                    : 'grouped'
            let chartLayout =
                this.state.blockSettings.chart_layout === 'horizontal'
                    ? 'horizontal'
                    : 'vertical'
            return (
                <ResponsiveBar
                    indexBy="id"
                    colors={useColors}
                    margin={{ top: 20, right: 20, bottom: 70, left: 60 }}
                    padding={0.3}
                    keys={['value']}
                    layout={chartLayout}
                    groupMode={groupMode}
                    useMesh={false}
                    data={this.state.transformedData}
                    enableLabel={true}
                    axisTop={null}
                    axisRight={null}
                    labelTextColor={{ theme: 'labels.text.fill' }}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend:
                            chartLayout === 'horizontal'
                                ? kedo.t('Totals')
                                : kedo.t('Items'),
                        legendPosition: 'middle',
                        legendOffset: 32,
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        format: (e) => Math.floor(e) === e && e,
                        tickRotation: 0,
                        legend:
                            chartLayout === 'horizontal'
                                ? kedo.t('Items')
                                : kedo.t('Totals'),
                        legendPosition: 'middle',
                        legendOffset: -50,
                    }}
                    theme={{
                        axis: {
                            legend: {
                                text: {
                                    fontWeight: 700,
                                },
                            },
                            ticks: {
                                text: {
                                    fontWeight: 300,
                                },
                            },
                        },
                    }}
                />
            )
        } else if (chartFormat === 'line') {
            let transformedData = this.transformLineData(data)
            return (
                <ResponsiveLine
                    colors={useColors}
                    data={transformedData}
                    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
                    xScale={{ type: 'point' }}
                    yScale={{
                        type: 'linear',
                        min: 'auto',
                        max: 'auto',
                        stacked: true,
                        reverse: false,
                    }}
                    yFormat=" >-.2f"
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        orient: 'bottom',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: kedo.t('Date'),
                        legendOffset: 36,
                        legendPosition: 'middle',
                    }}
                    axisLeft={{
                        orient: 'left',
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: kedo.t('Count'),
                        legendOffset: -40,
                        legendPosition: 'middle',
                    }}
                    pointSize={4}
                    pointColor={{ theme: 'background' }}
                    pointBorderWidth={2}
                    pointBorderColor={{ from: 'serieColor' }}
                    pointLabelYOffset={-12}
                    useMesh={false}
                    legends={[
                        {
                            anchor: 'bottom-right',
                            direction: 'column',
                            justify: false,
                            translateX: 100,
                            translateY: 0,
                            itemsSpacing: 0,
                            itemDirection: 'left-to-right',
                            itemWidth: 80,
                            itemHeight: 20,
                            itemOpacity: 0.75,
                            symbolSize: 12,
                            symbolShape: 'circle',
                            symbolBorderColor: 'rgba(0, 0, 0, .5)',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemBackground: 'rgba(0, 0, 0, .03)',
                                        itemOpacity: 1,
                                    },
                                },
                            ],
                        },
                    ]}
                />
            )
        }

        return (
            <ResponsivePie
                data={this.transFormData(data)}
                margin={{ top: 0, right: 40, bottom: 80, left: 40 }}
                innerRadius={0.8}
                activeOuterRadiusOffset={10}
                padAngle={0.7}
                arcLinkLabelsThickness={1}
                cornerRadius={3}
                onClick={(node, event) => {
                    if (node && node.data && node.data.kedoId) {
                        window.location.href =
                            '/contentdossier/' + node.data.kedoId
                    }
                }}
                colors={useColors}
                borderWidth={1}
                borderColor={{ from: 'color', modifiers: [['brighter', 3]] }}
                radialLabelsSkipAngle={10}
                radialLabelsTextXOffset={6}
                radialLabelsTextColor="#333333"
                radialLabelsLinkOffset={0}
                radialLabelsLinkDiagonalLength={16}
                radialLabelsLinkHorizontalLength={24}
                radialLabelsLinkStrokeWidth={1}
                slicesLabelsSkipAngle={10}
                slicesLabelsTextColor="#333333"
                animate={true}
                motionStiffness={90}
                enableArcLinkLabels={false}
                enableArcLabels={false}
                motionDamping={15}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        translateY: 0,
                        translateX: -50,
                        justify: false,
                        itemWidth: 100,
                        itemHeight: 25,
                        itemTextColor: '#000000',
                        itemDirection: 'left-to-right',
                        symbolSize: 15,
                        symbolShape: 'circle',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemTextColor: '#000000',
                                },
                            },
                        ],
                    },
                ]}
            />
        )
    }

    renderData(data) {
        if (!data || data.length <= 0) {
            return ''
        }

        let scheme =
            this.state.blockSettings && this.state.blockSettings.scheme
                ? this.state.blockSettings.scheme
                : 'blues'
        let useColors = scheme ? { scheme: scheme } : { scheme: 'nivo' }
        let chartFormat = this.state.blockSettings?.chart_format
        if (
            this.state.blockSettings.use_creation_date ||
            this.state.blockSettings.date_field
        ) {
            return this.renderDateOrientatedCharts(chartFormat, data, useColors)
        }

        return this.renderNoneDateOrientatedCharts(chartFormat, data, useColors)
    }

    render() {
        const kedo = this.props.kedo

        return (
            <div
                ref={this.parRef}
                className={!this.props.dashboard ? 'kedo-block' : ''}
                style={{
                    height: '100%',
                    marginBottom: '30px',
                    minHeight: '300px',
                    minWidth: '300px',
                    display: 'grid',
                }}
            >
                <BlockToolbar
                    setFilterAndFetchData={this.setFilterAndFetchData}
                    onClose={this.props.onClose}
                    onSuccess={this.props.onSuccess}
                    block={this.props.block}
                    kedo={kedo}
                    onMax={this.props.onMax}
                    fetchData={() => this.fetchData()}
                />
                {this.props.getBlockHeader
                    ? this.props.getBlockHeader(
                          this.props.block,
                          this.props.dossier
                      )
                    : null}
                {this.state.loading ? (
                    <center>
                        <LoadingDefault />
                    </center>
                ) : (
                    this.renderData(this.state.data)
                )}
            </div>
        )
    }
}
